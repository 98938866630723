
























































































































































import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import { InputSetups } from '@/mixins/input-setups'
import Placeholders from '@/mixins/placeholders/placeholders'
import ModuleConfigSettingsWrapper from '@/includes/logic/Modules/components/ModuleConfigSettingsWrapper.vue'
import ModuleConfigSettings from '@/includes/logic/Modules/components/ModuleConfigSettings.vue'
import KnowledgeBaseHandlerModuleBuilder
  from "@/includes/logic/Modules/modules/modules/KnowledgeBaseHandlerModule/KnowledgeBaseHandlerModuleBuilder";
import { HandlerModeTypeEnum } from "./types";
import { KnowledgeBaseArticles } from '@/includes/logic/Modules/modules/modules/KnowledgeBaseHandlerModule/KnowledgeBaseHandlerModule'
import GoBackButton from "@/includes/logic/Modules/components/PageTitleButtons/GoBackButton.vue";
import SaveModuleButton from '@/includes/logic/Modules/components/PageTitleButtons/SaveModuleButton.vue'

import MultiMessageEditorWithMediaInput from 'piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue'
import { MediaEditorMode } from 'piramis-base-components/src/components/NewMessageEditors/types'
import { SelectOption } from "piramis-base-components/src/logic/types";
import TypedBlackWhiteListInput from 'piramis-base-components/src/components/Pi/fields/TypedBlackWhiteListInput/TypedBlackWhiteListInput.vue'
import SelectInput from "piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue";
import ChipsInput from 'piramis-base-components/src/components/Pi/fields/ChipsInput/ChipsInput.vue'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import EditorInput from 'piramis-base-components/src/components/Pi/fields/EditorInput/EditorInput.vue'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import NumberInput from 'piramis-base-components/src/components/Pi/fields/NumberInput/NumberInput.vue'
import { IterableItemFactory, IterableListItem } from "piramis-base-components/src/shared/utils/IterableItemFactory";
import AddEntityCard from 'piramis-base-components/src/components/AddEntityCard.vue'

import { Component, Mixins, VModel, Watch } from 'vue-property-decorator'
import { plainToInstance } from "class-transformer";

@Component({
  components: {
    PageTitle,
    MultiMessageEditorWithMediaInput,
    TypedBlackWhiteListInput,
    SelectInput,
    ChipsInput,
    EditorInput,
    NumberInput,
    GoBackButton,
    SaveModuleButton,
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
    EmptyData,
    AddEntityCard
  },
  data() {
    return {
      MediaEditorMode,
    }
  }
})
export default class KnowledgeBaseHandlerModuleView extends Mixins(ModuleBindings, InputSetups, TariffsTagsHelper, Placeholders) {
  @VModel() module!: KnowledgeBaseHandlerModuleBuilder

  frontendArticles: Array<IterableListItem<KnowledgeBaseArticles>> | null = []

  iterableItemFactory = new IterableItemFactory()

  @Watch('frontendArticles', { deep: true })
  onArticlesChange(articles: Array<IterableListItem<KnowledgeBaseArticles>>) {
    this.setArticles(articles)
  }

  get handlerModeTypeOptions(): Array<SelectOption> {
    return [
      {
        label: this.$t('knowledge_base_handler_mode_type_all').toString(),
        value: HandlerModeTypeEnum.All
      },
      {
        label: this.$t('knowledge_base_handler_mode_type_admin').toString(),
        value: HandlerModeTypeEnum.Admin
      },
    ]
  }

  addTemplate() {
    if (this.frontendArticles) {
      this.frontendArticles.push(this.iterableItemFactory.create(this.getArticleTextTemplate()))
    }
  }

  deleteTemplate(guid: string) {
    if (this.frontendArticles) {
      this.frontendArticles = this.frontendArticles.filter(a => a.guid !== guid)
    }
  }

  getArticleTextTemplate() {
    return plainToInstance(KnowledgeBaseArticles, {
      text: [
        {
          attachments: [],
          text: '',
          buttons: [],
          remove_previous: false,
          pin: false,
          disable_link_preview: false,
          disable_notify: false,
          topics: [],
          protect_content: false,
          send_after: 0,
          remove_after: 0
        }
      ]
    })
  }

  setArticles(articles: Array<IterableListItem<KnowledgeBaseArticles>>) {
    this.module.model.params.articles = this.getRawArticles(articles)
  }

  getRawArticles(articles: Array<IterableListItem<KnowledgeBaseArticles>>) {
    return articles.map(a => a.value)
  }

  getFrontendArticles() {
    return this.module.model.params.articles.map(a => this.iterableItemFactory.create(a))
  }

  mounted() {
    this.frontendArticles = this.getFrontendArticles()
  }
}
